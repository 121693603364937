<template>
  <div class="main-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Layout",
};
</script>

<style lang="less">
.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fff;
}
</style>
