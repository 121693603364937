<template>
  <Layout class="main-container ivu-row-height-left">
    <Row :gutter="16" style="height: 100%">
      <Col class="layout-left" span="6">
        <Tree :data="moduleList"></Tree>
      </Col>
      <Col span="18" style="background: #fff; height: 100%; overflow: auto">
        <Row align="top" class="mt5">
          <Button
            class="mt10"
            icon="md-add-circle"
            type="success"
            v-if="$canAction(['Sys_UC_Edit'])"
            @click="handleAddNew"
            >新增用例</Button
          >
        </Row>
        <Row style="height: 100%">
          <Table
            class="mt10 main-table"
            ref="table"
            style="width: 100%"
            border
            stripe
            highlight-row
            :columns="columns"
            :data="UseCaseOfSelected"
            :loading="loading"
          >
            <template slot="Action" slot-scope="{ row }">
              <Button
                size="small"
                type="success"
                class="ml2"
                v-if="$canAction(['Sys_UC_Edit'])"
                @click="handleOpenEdit(row)"
                >编辑</Button
              >
              <Button
                size="small"
                type="error"
                class="ml2"
                v-if="$canAction(['Sys_UC_Del'])"
                @click="handleOpenDelete(row)"
                >删除</Button
              >
            </template>
          </Table>
        </Row>
      </Col>
    </Row>
    <Modal v-model="UsecaseView" title="编辑用例" class-name="i-modal">
      <Form
        ref="form"
        :model="formrow"
        class="modelViewFromCheck"
        :rules="ruleValidate"
      >
        <Row :gutter="16">
          <Col span="12">
            <FormItem label="用例编号">
              <Input type="text" v-model="formrow.UsecaseID" disabled></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="UsecaseEName" label="用例英文名">
              <Input type="text" v-model="formrow.UsecaseEName"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="12">
            <FormItem prop="UsecaseTitle" label="用例名称">
              <Input type="text" v-model="formrow.UsecaseTitle"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="UsecaseDesc" label="用例描述">
              <Input type="text" v-model="formrow.UsecaseDesc"></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div slot="footer">
        <Button @click="UsecaseView = false">取消</Button>
        <Button type="primary" @click="handleSave" :loading="SaveLoading">
          {{ !SaveLoading ? "保存" : "保存中..." }}
        </Button>
      </div>
    </Modal>
  </Layout>
</template>
<script>
import api from "@/api";
import layout from "@/components/layout/index.vue";
import { delayFunc } from "@/utils";
export default {
  name: "UsecaseManege",
  components: { layout },
  data() {
    return {
      SaveLoading: false,
      isNew: false,
      moduleList: [],
      selectedObj: {},
      formrow: {},
      UseCaseOfSelected: [],
      loading: false,
      rowCopy: null,
      UsecaseView: false,
      ruleValidate: {
        UsecaseEName: [
          { required: true, message: "用例英文名不能为空", trigger: "blur" },
        ],
        UsecaseTitle: [
          { required: true, message: "用例名称不能为空", trigger: "blur" },
        ],
        UsecaseDesc: [
          { required: true, message: "用例描述不能为空", trigger: "blur" },
        ],
      },
      columns: [
        {
          title: "用例编码",
          key: "UsecaseID",
          sortable: "custom",
          ellipsis: true,
          minWidth: 80,
          align: "center",
        },
        {
          title: "用例名称",
          key: "UsecaseTitle",
          sortable: "custom",
          ellipsis: true,
          minWidth: 120,
          align: "center",
        },
        {
          title: "用例英文名",
          key: "UsecaseEName",
          sortable: "custom",
          ellipsis: true,
          minWidth: 120,
          align: "center",
        },
        {
          title: "用例描述",
          key: "UsecaseDesc",
          sortable: "custom",
          ellipsis: true,
          minWidth: 100,
          align: "center",
        },
      ],
    };
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
        fixed: "right",
      });
    }
    this.getModuleList();
  },
  mounted() {
    this.$bus.$on("refreshPage", () => {
      this.moduleList = [];
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.getModuleList();
      }
    });
  },
  methods: {
    async handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const _this = this;
          _this.isSaving = true;
          try {
            var result = await api.SaveModuleUsecase({
              entity: _this.formrow,
              type: _this.formrow.isAdd ? "add" : "edit",
            });
            if (result.Status === 100) {
              _this.$Message.success("保存成功");
              if (_this.formrow.isAdd) _this.formrow = result.Data;
              _this.isSaving = false;
              await delayFunc();
              _this.handleTreeItem(_this.selectedObj);
              _this.UsecaseView = false;
            } else {
              _this.$Message.error(result.Msg);
              _this.isSaving = false;
            }
          } catch (error) {
            _this.isSaving = false;
          }
        } else {
          this.$Message.error("填写信息有误！");
        }
      });
    },
    handleOpenEdit(row) {
      this.formrow = JSON.parse(JSON.stringify(row));
      this.$refs.form.resetFields();
      this.formrow.isEdit = true;
      this.UsecaseView = true;
    },
    async handleAddNew() {
      try {
        this.$refs.form.resetFields();
        const res = await api.GenerateSystemCode({
          strOperationCode: "UsecaseCode",
        });
        if (res.Status === 100) {
          let newRow = {
            UsecaseID: res.Data,
            ModuleID: this.selectedObj.ModID,
            isAdd: true,
          };
          this.formrow = newRow;
          this.UsecaseView = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    renderContent(h, { data }) {
      return h(
        "div",
        {
          class: {
            "tree-content": true,
          },
          style: {
            background:
              data.data.ModID === this.selectedObj.ModID
                ? "#5cadff"
                : "#ffffff",
            display: "inline-block",
            width: "100%",
            height: "24px",
            lineHeight: "24px",
            cursor: "pointer",
          },
          on: {
            click: () => {
              this.handleTreeItem(data.data);
            },
          },
        },
        [
          h(
            "span",
            {
              style: {
                marginLeft: "10px",
                color:
                  data.data.ModID === this.selectedObj.ModID
                    ? "#fff"
                    : "#17233d",
                cursor: "pointer",
              },
            },
            data.title
          ),
        ]
      );
    },
    handleOpenDelete(row) {
      const _this = this;
      this.$Modal.confirm({
        title: "提示",
        closable: true,
        loading: true,
        content: "确定删除用例【" + row.UsecaseTitle + "】吗？",
        onOk: function () {
          _this.handleDelete(row.UsecaseID);
        },
        onCancel: () => {
          _this.$Modal.remove();
        },
      });
    },
    async handleDelete(id) {
      try {
        const res = await api.DeleteModuleUsecase({
          id: id,
        });
        if (res.Status === 100) {
          this.$Message.success("删除成功");
          this.$Modal.remove();
          this.UseCaseOfSelected.splice(
            this.UseCaseOfSelected.findIndex((item) => item.UsecaseID === id),
            1
          );
        } else {
          this.$Message.error(res.Msg);
          this.$Modal.remove();
        }
      } catch (error) {
        this.$Modal.remove();
      }
    },
    async handleTreeItem(row) {
      this.selectedObj = row;
      this.selectedObj.PModuleName = row.ModuleTitle;
      try {
        this.loading = true;
        const res = await api.GetUsecaseByMenu({
          mnueid: this.selectedObj.ModID,
        });
        if (res.Status === 100) {
          res.Data.forEach((item) => {
            item.isEdit = false;
          });
          this.UseCaseOfSelected = res.Data;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async getModuleList() {
      try {
        const res = await api.GetAllModuleList({});
        this.moduleList = res.Data;
        let Root = [];
        let Module1 = [];
        let Module2 = [];
        let Module3 = [];

        this.moduleList.map((item) => {
          switch (item.ModuleLevel) {
            case 0:
              Root.push({
                data: item,
                title: item.ModuleTitle,
                expand: true,
                children: [],
                render: this.renderContent,
              });
              break;
            case 1:
              Module1.push({
                data: item,
                title: item.ModuleTitle,
                expand: true,
                children: [],
                render: this.renderContent,
              });
              break;
            case 2:
              Module2.push({
                data: item,
                title: item.ModuleTitle,
                expand: false,
                children: [],
                render: this.renderContent,
              });
              break;
            case 3:
              Module3.push({
                data: item,
                title: item.ModuleTitle,
                expand: false,
                children: [],
                render: this.renderContent,
              });
              break;
          }
        });
        Module3.map((item) => {
          Module2.map((cell) => {
            if (item.data.PModuleID === cell.data.ModID) {
              cell.children.push(item);
            }
          });
        });
        Module2.map((item) => {
          Module1.map((cell) => {
            if (item.data.PModuleID === cell.data.ModID) {
              cell.children.push(item);
            }
          });
        });
        Root[0].children = Module1;
        this.moduleList = Root;
        if (this.moduleList[0]) {
          this.handleTreeItem(this.moduleList[0].data);
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
  },
};
</script>

<style lang="less">
.layout-flex .ivu-table-wrapper {
  overflow: auto;
}
.layout-flex {
  height: 100%;
}
.tree-content:hover {
  background: #e8eaec !important;
}
</style>
